*, *:before, *:after {
  box-sizing: border-box;
}

a:visited, a:link {
  text-decoration: none;
}

input:focus {
  outline: none;
}

button {
  color: inherit;
}
