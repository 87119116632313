$page-width: 928px;

/*
Default weights:
  300
  600?
 */
$light-weight: 300;
$regular-weight: 400;
$bold-weight: 600;

$text-transparency: .2;
