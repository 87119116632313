@import 'colors';

/* https://css-tricks.com/snippets/sass/centering-mixin/ */
@mixin centerer($horizontal: true, $vertical: true) {
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin regular() {
  font-family: 'Open Sans', sans-serif;
  color: transparentize($text-1, $text-transparency);
}
