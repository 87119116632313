@import '../../scss/globals/all';

.container {
  width: 320px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  background-color: $bg-0;

  border-top: 6px solid $pc-1;
  border-radius: 6px;

  box-shadow: 0 2px 6px 0 $bg-3;
}

.body {
  width: 100%;
  height: 100%;

  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.footer {
  width: 100%;

  text-align: center;

  background-color: $bg-2;
  padding: 20px;
}
