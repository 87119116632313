@import '../globals/all';
@import 'text';

.inp {
  @extend .light-1;

  box-shadow: inset 0 1px 4px 0 $bg-3;
  margin: 10px 0;
  border: 1px solid $bg-3;
  border-radius: 3px;
  padding: 8px 12px;

  height: 44px;
  font-size: 14px;
  line-height: 20px;

  transition: border 0.2s ease-in-out;
  &:focus {
    border: 1px solid $pc-1;
  }
}
